.contact {
    flex-direction: row;
    /* Auf Tablets wird es horizontal */
    justify-content: space-between;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.contact label {
    display: flex;
    flex-direction: column;

}

.contact label input, .contact label textarea {
    font-family: "Inter", sans-serif;
    border-radius: 5pt;
    padding: 12pt 30pt;
    font-weight: 500;
    font-size: 12pt;
    border: none;
}