/* Allgemeines Styling */
.about {
    text-align: left;
    padding: 0 1.5rem;
    /* Mobile-First: Weniger Padding */
    box-sizing: border-box;
}

.about h1 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #2b2b2b;
}

.about .about-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50pt;
    /* Weniger Abstand auf mobilen Geräten */
}

.about .about-text {
    max-width: 100%;
    /* Volle Breite auf mobilen Geräten */
    width: 100%;
    margin-bottom: 1.5rem;
}

.about .about-images {
    max-width: 300px;
    /* Volle Breite auf mobilen Geräten */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3.5rem;
}

.about img {
    max-width: 300px;
    height: auto;
    border-radius: 8px;
}

/* Text Styling */
.about-text p,
.about ul li {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.about h2 {
    font-size: 20px;
    margin-bottom: 1.5rem;
    color: #F28E2B;
}

.about h3 {
    font-size: 18px;
    font-style: italic;
    margin-bottom: 0.5rem;
}

.about ul {
    padding-left: 1.5rem;
    margin-bottom: 2rem;
}

/* Tablet Breakpoint */
@media screen and (min-width: 768px) {
    .about {
        padding: 0 3rem;
        /* Mehr Padding auf Tablet */
    }

    .about h1 {
        font-size: 24px;
        margin-bottom: 2rem;
    }

    .about h2 {
        font-size: 20px;
    }

    .about .about-box {
        flex-direction: row;
        /* Horizontale Anordnung auf Tablet */
        gap: 75pt;
        /* Weniger Abstand als auf Desktop */
    }

    .about .about-text {
        max-width: 60%;
        /* Textbereich auf 60% beschränken */
    }

    .about .about-images {
        max-width: 40%;
        /* Bildbereich auf 40% beschränken */
    }
}

/* Desktop Breakpoint */
@media screen and (min-width: 1024px) {
    .about {
        padding: 0 4.5rem;
        /* Desktop Padding */
    }

    .about h1 {
        font-size: 26px;
        margin-bottom: 2.5rem;
    }

    .about h2 {
        font-size: 20px;
    }

    .about h3 {
        font-size: 18px;
    }

    .about .about-box {
        gap: 100pt;
        /* Größerer Abstand auf Desktop */
    }

    .about-text p,
    .about ul li {
        font-size: 16px;
    }
}