/* Grundlegendes Styling für die FAQ-Seite */
.faq {
    text-align: left;
    width: 100%;
    padding: 0 1.5rem;
    /* Mobile-First: Weniger Padding auf mobilen Geräten */
    box-sizing: border-box;
}

.faq h1 {
    text-align: center;
    font-size: 22px;
    /* Kleinere Schriftgrößen für mobile Geräte */
    margin-bottom: 2rem;
}

.faq h2 {
    font-size: 18px;
    /* Etwas kleinere Überschrift für mobile Geräte */
}

.faq-item {
    margin-bottom: 20px;
}

/* Collapsible Styling */
.collapsible {
    background-color: #96714a;
    color: white;
    cursor: pointer;
    padding: 15px;
    /* Weniger Padding auf mobilen Geräten */
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 14px;
    /* Kleinere Schriftgrößen auf mobilen Geräten */
    border-radius: 5px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
}

.active,
.collapsible:hover {
    background-color: #5e472e;
}

.content {
    display: none;
    overflow: hidden;
    font-size: 14px;
    padding: 10px 0;
}

.content.show {
    display: block;
}

.collapsible:after {
    content: '+';
    /* Unicode character for "plus" sign (+) */
    font-size: 20px;
    color: #ffffff;
    float: right;
    margin-left: 5px;
}

.active:after {
    content: "-";
    /* Unicode character for "minus" sign (-) */
}

/* Tablet Breakpoint */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .faq {
        padding: 0 3rem;
        /* Mehr Padding auf Tablets */
    }

    .faq h1 {
        font-size: 24px;
        /* Größere Schriftgrößen für Tablets */
        margin-bottom: 2.5rem;
    }

    .faq h2 {
        font-size: 20px;
        /* Größere Überschrift auf Tablets */
    }

    .collapsible {
        padding: 18px;
        /* Etwas mehr Padding auf Tablets */
        font-size: 15px;
    }

    .content {
        font-size: 15px;
    }
}

/* Desktop Breakpoint */
@media screen and (min-width: 1024px) {
    .faq {
        padding: 0 4.5rem;
        /* Mehr Padding auf Desktop */
    }

    .faq h1 {
        font-size: 26px;
        margin-bottom: 2.5rem;
    }

    .faq h2 {
        font-size: 20px;
    }

    .collapsible {
        padding: 18px;
        font-size: 15px;
    }

    .content {
        font-size: 16px;
        /* Größere Schriftgrößen auf Desktop */
        padding: 12px 0;
    }
}