/* Grundlegendes Styling für die Imprint-Seite */
.imprint {
    text-align: left;
    padding: 0 1.5rem;
    /* Mobile-First: Weniger Padding auf mobilen Geräten */
    box-sizing: border-box;
}

.imprint h1 {
    font-size: 22px;
    /* Kleinere Schriftgrößen für mobile Geräte */
    text-align: center;
    margin-bottom: 2rem;
}

.imprint h2 {
    font-size: 18px;
    /* Kleinere Überschrift für mobile Geräte */
    color: #F28E2B;
    margin-bottom: 1.5rem;
}

.imprint p,
.imprint ul li {
    font-size: 14px;
    /* Konsistente Schriftgröße für mobile Geräte */
    line-height: 1.6;
    margin-bottom: 1rem;
}

/* Tablet Breakpoint */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .imprint {
        padding: 0 3rem;
        /* Mehr Padding auf Tablets */
    }

    .imprint h1 {
        font-size: 24px;
        /* Größere Schriftgrößen für Tablets */
        margin-bottom: 2.5rem;
    }

    .imprint h2 {
        font-size: 20px;
        /* Etwas größere Überschrift auf Tablets */
    }

    .imprint p,
    .imprint ul li {
        font-size: 15px;
        /* Leicht größere Schrift für bessere Lesbarkeit */
    }
}

/* Desktop Breakpoint */
@media screen and (min-width: 1024px) {
    .imprint {
        padding: 0 4.5rem;
        /* Mehr Padding auf Desktop */
    }

    .imprint h1 {
        font-size: 26px;
        /* Größere Schriftgrößen für Desktop */
        margin-bottom: 2.5rem;
    }

    .imprint h2 {
        font-size: 20px;
        /* Konsistente Überschriftgröße */
        margin-bottom: 2rem;
    }

    .imprint p,
    .imprint ul li {
        font-size: 16px;
        /* Größere Schriftgröße für bessere Lesbarkeit */
        line-height: 1.8;
        /* Mehr Zeilenabstand für Desktop */
    }
}