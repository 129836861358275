/* Grundlegendes Styling */
.products {
    text-align: left;
    padding: 0 1.5rem;
    /* Mobile-First-Ansatz: Weniger Padding auf mobilen Geräten */
    box-sizing: border-box;
}

.products h1 {
    font-size: 22px;
    /* Kleinere Schriftgrößen für mobile Geräte */
    text-align: center;
    margin-bottom: 1.5rem;
}

.products h2 {
    color: #F28E2B;
    font-size: 18px;
    margin-bottom: 1.5rem;
}

.products p,
.products ul li {
    font-size: 14px;
}

/* Produktcontainer */
.product {
    display: flex;
    flex-direction: column;
    /* Mobile-First: vertikale Anordnung auf kleinen Bildschirmen */
    justify-content: center;
    align-items: center;
    gap: 50pt;
    /* Weniger Abstand auf mobilen Geräten */
}

.product .product-info {
    max-width: 100%;
    /* Füllt auf mobilen Geräten den gesamten verfügbaren Platz */
    width: 100%;
}

.product .product-example {
    max-width: 100%;
    /* Füllt auf mobilen Geräten den gesamten verfügbaren Platz */
    width: 100%;
}

.product a {
    text-decoration: none;
    cursor: pointer;
}

/* App Download Box */
.product .to-app-box {
    display: flex;
    flex-direction: column;
    /* Mobile-First: vertikale Anordnung */
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    gap: 1rem;
}

.product .to-app-box .to-app {
    width: 100%;
    /* Breite auf mobilen Geräten angepasst */
    max-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.qr-code svg path:first-of-type {
    fill: transparent;
}

.qr-code svg path:last-of-type {
    fill: #fff;
}

/* Tablet Breakpoint */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .products {
        padding: 0 3rem;
        /* Mehr Padding auf Tablet-Geräten */
    }

    .products h1 {
        font-size: 24px;
        margin-bottom: 2rem;
    }

    .products h2 {
        font-size: 20px;
    }

    .product {
        flex-direction: row;
        /* Auf Tablet-Geräten wieder nebeneinander */
        gap: 75pt;
        /* Weniger Lücke als auf Desktop */
    }

    .product .product-info {
        max-width: 500px;
    }

    .product .product-info p {
        text-align: left;
    }

    .product .product-example {
        max-width: 350px;
    }

    .product .to-app-box {
        flex-direction: column;
    }

    .product .to-app-box .to-app {
        max-width: 275px;
    }
}

/* Desktop Breakpoint */
@media screen and (min-width: 1024px) {
    .products {
        padding: 0 4.5rem;
    }

    .products h1 {
        font-size: 26px;
        margin-bottom: 2.5rem;
    }

    .products h2 {
        font-size: 20px;
    }

    .product {
        flex-direction: row;
        /* Auf Desktop wieder nebeneinander */
        gap: 100pt;
        /* Größerer Abstand zwischen den Elementen */
    }

    .product .product-info {
        max-width: 550px;
    }

    .product .product-example {
        max-width: 400px;
    }

    .product .to-app-box {
        flex-direction: row;
    }

    .product .to-app-box .to-app {
        max-width: 275px;
    }
}