footer {
    font-size: 12px;
    color: #96714a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2.5rem;
    background-color: #ffffff;
}

footer ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    align-self: center;
    margin: 0;
    padding: 1rem 0;
}

footer p {
    margin: 0;
    font-size: 8pt;
}

footer a {
    color: #96714a;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}