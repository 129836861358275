/* Grundlegendes Styling für die Home-Seite */
.home {
    display: flex;
    flex-direction: column;
    /* Mobile-First: vertikale Anordnung */
    padding: 1.5rem;
    box-sizing: border-box;
}

.home .landing-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.home p {
    font-size: 14pt;
    margin-bottom: 45px;
    width: 100%;
}

/* Tablet Breakpoint */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .home {
        flex-direction: row;
        /* Auf Tablets wird es horizontal */
        justify-content: space-between;
        padding: 2rem 3rem;
    }

    .home .landing-box {
        width: 60%;
        /* Textbox nimmt 60% der Breite ein */
    }

    .home p {
        margin-bottom: 40px;
        text-align: left;
    }
}

/* Desktop Breakpoint */
@media screen and (min-width: 1024px) {
    .home {
        flex-direction: row;
        justify-content: space-between;
        padding: 2rem 4.5rem;
        /* Mehr Padding auf größeren Bildschirmen */
    }

    .home .landing-box {
        width: 50%;
        /* Auf größeren Bildschirmen nimmt der Text 50% ein */
    }

    .home p {
        margin-bottom: 50px;
        text-align: left;
    }
}