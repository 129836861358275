@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');



/* Header Styling */
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  /* Für mobile Geräte */
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  /* Hintergrundfarbe für bessere Lesbarkeit */
  z-index: 10;
}

header .header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

header .brand_logo {
  width: 150px;
  /* Kleinere Logos auf mobilen Geräten */
}

header ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: none;
  /* Standardmäßig ausgeblendet */
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  top: 80px;
  right: 0;
  width: 100%;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Wenn das Menü geöffnet ist, zeige die Navigation */
header ul.open {
  display: flex;
  background-color: #efe3cb;
}

/* Links Styling */
header a {
  color: #96714a;
  text-decoration: none;
  font-size: 12pt;
  font-weight: 400;
}

header a:hover {
  text-decoration: underline;
}

/* Burger-Button für Mobile und Tablet */
.burger-menu {
  display: block;
  cursor: pointer;
}

.burger-menu div {
  width: 25px;
  height: 3px;
  background-color: #96714a;
  margin: 5px 0;
  transition: 0.3s;
}

/* Animierter Burger-Button bei offenem Menü */
.burger-menu.open div:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 5px);
}

.burger-menu.open div:nth-child(2) {
  opacity: 0;
}

.burger-menu.open div:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -5px);
}

/* Tablet Breakpoint */
@media (min-width: 768px) and (max-width: 1023px) {
  header {
    height: 100px;
    padding: 0 2rem;
  }

  header .brand_logo {
    width: 180px;
  }

  /* Burger-Menü weiterhin sichtbar */
  header ul {
    display: none;
    /* Navigation standardmäßig ausgeblendet */
    position: absolute;
  }

  /* Offenes Menü wird auch auf Tablets sichtbar */
  header ul.open {
    display: flex;
  }
}

/* Desktop Breakpoint */
@media (min-width: 1024px) {
  header {
    height: 100px;
    padding: 0 2.375rem;
  }

  .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  header .brand_logo {
    width: 195px;
  }

  /* Navigation auf Desktop immer sichtbar */
  header ul {
    display: flex;
    flex-direction: row;
    position: static;
    gap: 1.5rem;
    box-shadow: none;
    justify-content: flex-end;
  }

  .burger-menu {
    display: none;
    /* Burger-Icon auf Desktop ausblenden */
  }
}