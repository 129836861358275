@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Varela+Round&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #efe3cb;
  color: #2b2b2b;
  font-family: "Inter", sans-serif;
  line-height: 1.6;
}

/* Mobile First Styling */
.wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
}

h1 {
  font-size: 24pt;
  text-align: left;
}

h1, h2, h3, h4, h5 {
  font-family: "Varela Round", sans-serif;
}

p {
  text-align: left;
  font-size: 12pt;
}

.btn {
  background-color: #96714a;
  color: #ffffff;
  border: none;
  border-radius: 5pt;
  padding: 12pt 30pt;
  font-weight: 500;
  font-size: 12pt;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}

.btn p {
  text-align: center;
}

.btn:hover {
  background-color: #5e472e;
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  justify-content: center;
  background-image: url('assets/img/bg.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  padding-top: 7.5rem;
  padding-bottom: 3.5rem;
}

.App-link {
  color: #96714a;
}

/* Tablet Breakpoint */
@media (min-width: 768px) and (max-width: 1023px) {
  .wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 6rem auto;
    max-width: 90%;
  }

  h1 {
    font-size: 26pt;
  }

  p {
    font-size: 12pt;
  }
}

/* Desktop Breakpoint */
@media (min-width: 1024px) {
  .wrapper {
    max-width: 80%;
  }

  h1 {
    font-size: 30pt;
  }

  p {
    font-size: 12pt;
  }

}